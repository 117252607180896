<script>
import { Doughnut } from "vue-chartjs";

export default {
    props: [
        'chartdata'
    ],

  extends: Doughnut,
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            backgroundColor: this.chartdata.color,
            borderWidth: 0,
            cutoutPercentage: 10,
            data: this.chartdata.data,
          } 
        ]
      },
      { responsive: true, maintainAspectRatio: false,  width: 350, height: 350 }
    );
  }
};
</script> 