<template>
  <v-container>
      <v-row class="d-flex">
        <v-col cols="12" class="pa-0">
           <!-- <v-flex class="onderschrift"><p>Klik de betalingen aan om te zien wat voor<br> invloed het heeft als je ze niet doet.</p></v-flex> -->
        </v-col> 
      </v-row>
      <v-row class="d-flex justify-center" style="min-height: 143px;">
        <v-col cols="5" class="payment-box rounded-lg">
          <v-flex class="wrapper">
             <v-flex dense cols="3" sm="1" md="4" lg="3" flat>
              <v-flex class="logo-box"><v-icon>fas fa-clock</v-icon></v-flex>
              </v-flex>
              <v-flex dense class="price" cols="3" sm="1" md="4" lg="3" flat>
                  <p class="heading-5 font-clock">12:00 - 13:00</p>
                  <p>Meest vatbaar moment voor uitgave</p>
              </v-flex>
          </v-flex>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="5" class="payment-box rounded-lg">
          <v-flex class="wrapper">
             <v-flex dense cols="3" sm="1" md="4" lg="3" flat>
              <v-flex class="logo-box"><v-icon>fas fa-euro-sign</v-icon></v-flex>
              </v-flex>
              <v-flex dense class="price" cols="3" sm="1" md="4" lg="3" flat>
                  <p class="heading-5 font-weight-medium">€12,30</p>
                  <p>Gemiddelde van alle uitgave</p>
              </v-flex>
          </v-flex>
        </v-col>
        <v-col cols="5" class="payment-box rounded-lg">
          <v-flex class="wrapper">
             <v-flex dense cols="3" sm="1" md="4" lg="3" flat>
              <v-flex class="logo-box"><v-icon>fas fa-map-marker-alt</v-icon></v-flex>
              </v-flex>
              <v-flex dense class="price" cols="3" sm="1" md="4" lg="3" flat>
                  <p class="heading-5 font-weight-medium">Breda</p>
                  <p>De meeste uitgaven Komen uit Breda</p>
              </v-flex>
          </v-flex>
        </v-col>
      </v-row>
    <v-row class="pt-10">
      <v-flex class="avatar-box">
         <img :src="item.avatar">
         <img class="avatar-overlay" :src="item.avatarOverlay">
         <img class="avatar-overlay" :src="item.avatarOverlay1">
      </v-flex>
    </v-row>
    <v-flex class="payment-btn-wrapper mt-10">
      <v-btn class="btn-payment" to="/profile-prediction" large color="primary" dark>
        Ga terug naar het overzicht
      </v-btn>
    </v-flex>
  </v-container>
</template>

<script>
export default {
    props: [
        'listdata'
    ],
    data: () => {
    return {
      pageTitle: 'Mijn DataMate',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      foodDataFilter: '',
      companies: [{
        name: 'Albert Hein',
        cat: 'Food', 
      },
      {
        name: 'Media Markt',
        cat: 'Tech', 
      },
      {
        name: 'Blokker',
        cat: 'Huishouden', 
      },
      {
        name: 'Baboon Chicken',
        cat: 'Food',
      }],
      item: {
                name: '', 
                cat: 'Food',
                price: 1234, 
                logo: require('@/assets/logos/default.png'),
                avatar: require('@/assets/avatar/head1.png'),
                avatarOverlay: require('@/assets/avatar/bril0.png'),
                avatarOverlay1: require('@/assets/avatar/bril0.png')
            },
        }
    },
    mounted () {
        this.$emit('createdTitle', this.pageTitle);
        this.makeProgress();
        this.changeAvatar();

        this.newFoodpercentage = this.foodpercentage
        this.newFoodItems = this.foodItems
        this.newTotalItems = this.totalItems
        this.foodDataFilter = this.foodData
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
      bookData() {
        return this.listdata.filter(listdata => listdata.cat === "Literatuur");
        },
      elecktronicaData() {
        return this.listdata.filter(listdata => listdata.cat === "Elecktronica");
      },        
    },
    methods: {
    changeItem : function(index){
            console.log(index)
            this.$delete(this.foodData, index)
            this.newFoodItems--;
            this.newTotalItems--;
          
            this.newFoodpercentage = this.newFoodItems / this.newTotalItems * 100
            
            this.changeAvatar();
            this.changeOverlay();      
    },
    changeAvatar : function() {
      if (this.newFoodpercentage >= 0 && this.newFoodpercentage < 20) {
        this.item.avatar = require('@/assets/avatar/full/avatar.png');
      } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
        this.item.avatar = require('@/assets/avatar/full/avatar1.png');
      }
      else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 60) {
        this.item.avatar = require('@/assets/avatar/full/avatar2.png');
      }
      else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
        this.item.avatar = require('@/assets/avatar/full/avatar3.png');
      }
      else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
        this.item.avatar = require('@/assets/avatar/full/avatar4.png');
      }
    },
    changeOverlay : function() {
        if (this.newBookpercentage > 0 && this.newBookpercentage < 20) {
          this.item.avatarOverlay = require('@/assets/avatar/avatar-bril.png');
        } else if (this.newBookpercentage > 19 && this.newBookpercentage < 100) {
          this.item.avatarOverlay = require('@/assets/avatar/avatar-bril.png');
        }
    },
    changeOverlay1 : function() {
        if (this.newelecktronicapercentage > 0 && this.newelecktronicapercentage < 30) {
          this.item.avatarOverlay1 = require('@/assets/avatar/bril0.png');
        } else if (this.newelecktronicapercentage > 29 && this.newelecktronicapercentage < 100) {
          this.item.avatarOverlay1 = require('@/assets/avatar/avatar-vr.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.bookItems = this.bookData.length
        this.bookpercentage = this.bookItems / this.totalItems * 100
        this.newBookpercentage = this.bookpercentage;

        this.elecktronicaItems = this.elecktronicaData.length
        this.elecktronicapercentage = this.elecktronicaItems / this.totalItems * 100
        this.newelecktronicapercentage = this.elecktronicapercentage;

        this.newFoodpercentage = this.foodpercentage
        this.changeAvatar();
        this.changeOverlay();
        this.changeOverlay1()

      }

  },
  
}
</script>
<style lang="scss" scoped>
.money-bar{
    input#input-6 {
      text-align: center !important;
  }
}
.avatar-box {
  height: 60vh;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
}
.v-select > .v-input__control > .v-input__slot {
  background: #222227;
}
.currency {
    width: 100%;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    color: #BDBDBD;
}

.onderschrift p {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    color: #BDBDBD;
    width: 100%;
}
.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100% !important;
}
.logo-box{
  width: 40px;
  min-height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-pay{
  font-size: 48px;
  color: #BDBDBD;
}

.price {
  text-align: center;
  .heading-5{
    font-size: 18px;
  }
  p{
    font-size: 12px;
    padding: 5px;
  }
}

.pay-wrapper-gegevens{
  .col-4{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wrapper{
    background: #222227;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;

    p {
      margin-top: 5px;
      margin-bottom: 0;
      color: #BDBDBD;
    }
}
.avatar-overlay{
    position: absolute;
    z-index: 1;
    height: 60vh !important;
    width: auto;
}
</style>