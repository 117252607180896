<template>
    <v-container>
      <v-row>
            <v-col cols="12">
              <a href="/intro">
                <v-img max-height="120" contain src="../assets/profile.png"></v-img>
              </a>
            </v-col>
            <v-col cols="12" class="chat-wrapper">
                <div class="arrow-up"></div>
                <div class="chat">
                  <a href="/intro">
                    <p>Datamate hier! Jouw nieuwste transactie statistieken liggen weer klaar. <strong>Klik op mij om </strong>te beginnen!</p>
                  </a>
                </div>
            </v-col>
        </v-row>
      <v-row class="my-12">
        <v-flex cols="12" class="payment-box ma-2 rounded-lg" v-for="item in listdata.slice().reverse()" :key="item.name">
          <v-col dense  cols="3" sm="1" md="4" lg="3" flat>
              <v-flex class="logo-box"><v-img max-width="40" max-height="40" contain :src="item.logo" /></v-flex>
          </v-col>
          <v-col dense  cols="6" sm="4" md="4" lg="6" flat>
              <v-flex><p class="heading-5 font-weight-medium">{{ item.name }}</p></v-flex>
              <v-flex><p class="font-weight-light categorie">{{ item.cat }}</p></v-flex>
          </v-col>
          <v-col dense class="price" cols="3" sm="1" md="4" lg="3" flat>
              <p class="heading-5 font-weight-medium">- {{ item.price }}</p>
          </v-col>
        </v-flex>
      </v-row>
      <v-flex class="payment-btn">
          <v-btn to="#" class="mx-2" fab dark large color="primary">
            <v-icon dark>
              mdi-cash
            </v-icon>
          </v-btn>
      </v-flex>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
      pageTitle: 'Overview',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      newBookpercentage: Number,
      item: {
                avatar: require('@/assets/avatar/head1.png'),
                avatarOverlay: require('@/assets/avatar/bril0.png'),
                avatarOverlay2: require('@/assets/avatar/bril0.png') 
            }
        }
    },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
      this.makeProgress();
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
      },
      bookData() {
        return this.listdata.filter(listdata => listdata.cat === "Literatuur");
      },
      elecktronicaData() {
        return this.listdata.filter(listdata => listdata.cat === "Elecktronica");
      },
    },
    methods : {

      changeAvatar : function() {
        if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
          this.item.avatar = require('@/assets/avatar/head.png');
        } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
          this.item.avatar = require('@/assets/avatar/head1.png');
        }
        else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 60) {
          this.item.avatar = require('@/assets/avatar/head2.png');
        }
        else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
          this.item.avatar = require('@/assets/avatar/head3.png');
        }
        else if (this.newFoodpercentage > 69 && this.newFoodpercentage < 101) {
          this.item.avatar = require('@/assets/avatar/head4.png');
        }
        },
        changeOverlay : function() {
        if (this.newelecktronicapercentage > 0 && this.newelecktronicapercentage < 30) {
          this.item.avatarOverlay = require('@/assets/avatar/bril0.png');
        } else if (this.newelecktronicapercentage > 30 && this.newelecktronicapercentage < 100) {
          this.item.avatarOverlay = require('@/assets/avatar/bril.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.newFoodpercentage = this.foodpercentage;

        this.bookItems = this.bookData.length
        this.bookpercentage = this.bookItems / this.totalItems * 100

        this.newBookpercentage = this.bookpercentage;

        this.changeAvatar();
        this.changeOverlay();
        this.changeOverlay1();

      }

    }
   
}
</script>
<style lang="scss" scoped>
.no-padding{
    padding: 0 !important;
}
a{
  text-decoration: none;
}
.chat {
    width: 80%;
    margin: 0 auto;
    background: #252728;
    padding: 20px 10px;
    border-radius: 10px;
    margin-top: -28px;
    font-size: 14px;
    text-align: center;
    z-index: 9;

    strong{
        color: #cbe9ba;
        font-weight: 800;
    }
    
    p{
        color: #ACADAC;
        margin-bottom: 0;
    }
}

h1{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #EAE6E4;
}

.chat-wrapper{
    margin-top: -40px;
    z-index: 9;
}

.mate{
    p{
        text-align: center;
        font-weight: 800;
        font-size: 13px;
        margin: 10px 0;
    }
}
.avatar {
  height: 50vh;
}
.payment-box {
  background-color: #252728;
  width: 100%;
  display: flex;

     p{
      margin-bottom: 2px !important;
    }
    .price {
      display: flex;
      align-items: center;
    }
}
.logo-box{
  max-width: 60px;
  min-height: 60px;
  border-radius: 100px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-box {
    min-height: 40vh;
}

.payment-btn{
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
}

.categorie {
  color: #BDBDBD !important;
}

.avatar-overlay{
      position: absolute;
    width: 80%;
}
@media (min-width:1025px) {
  .flex.payment-btn {
      margin-left: 132px;
      width: auto;
      bottom: 180px;
  }
  .v-btn--fab.v-size--large {
    height: 110px;
    width: 110px;
}
i.v-icon.notranslate.mdi.mdi-cash.theme--dark{
  font-size: 56px;
  
}
}
</style>
<style lang="scss">
.chat {
    width: 80%;
    margin: 0 auto;
    background: #252728;
    padding: 20px 10px;
    border-radius: 10px;
    margin-top: -28px;
    font-size: 14px;
    text-align: center;
    z-index: 9;

    strong{
        color: #CBE9BA;
        font-weight: 800;
    }
    
    p{
        color: #ACADAC;
        margin-bottom: 0;
    }
}

h1{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #EAE6E4;
}

.chat-wrapper{
    margin-top: -40px;
    z-index: 9;
}

.mate{
    p{
        text-align: center;
        font-weight: 800;
        font-size: 13px;
        margin: 10px 0;
    }
}

.map{
    margin: 0 auto;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #252728;
    top: 0px;
    text-align: center;
    margin: 0 auto;
    z-index: 9;
    margin-bottom: 28px;
}

.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100%;
}
</style>