<template>
  <v-container>
    <v-row>
      <v-flex class="wrapper-box">
        <v-flex class="wrapper-chart">
          <Chart :chartdata="percentage" />
        </v-flex>
      </v-flex>
      <v-flex class="onderschrift"><p>{{foodpercentage.toFixed(2)}}% van je uitgaven is aan eten</p></v-flex>    
    </v-row> 
    <v-row>
      <v-flex class="wrapper-box">
        <v-flex class="wrapper-chart">
          <Chart :chartdata="literatuur" />
        </v-flex>
      </v-flex>
      <v-flex class="onderschrift"><p>{{literatuurpercentage.toFixed(2)}}% van je uitgaven is aan Literatuur</p></v-flex>    
    </v-row> 
    <v-row>
      <v-flex class="wrapper-box">
        <v-flex class="wrapper-chart">
          <Chart :chartdata="elecktronica" />
        </v-flex>
      </v-flex>
      <v-flex class="onderschrift"><p>{{elecktronicapercentage.toFixed(2)}}% van je uitgaven is aan Elecktronica</p></v-flex>    
    </v-row> 
      
      <v-flex class="payment-btn-wrapper mt-4 mb-8">
        <v-btn class="btn-payment" to="/profile-zoom-data" large color="primary" dark>
          Waardoor komt dit?
        </v-btn>
    </v-flex>
  </v-container>
</template>
<script>
import Chart from '../components/Chart'

export default {
  components : {
    Chart
  },
  data () {
    return {
      pageTitle: 'Hoe komt dat?',
      percentage: {
        data: [],
        color: ['#3AF0DD', '#F95E7A']
      },
      foodpercentage: '',
      literatuur: {
        data: [],
        color: ['#FAF378', '#F95E7A']
      },
      literatuurpercentage: '',
      elecktronica: {
        data: [],
        color: ['#46C6FA', '#F95E7A']
      },
      elecktronicapercentage: '',

    }
  },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
    },

    created () {
      this.makeProgress()
    },

    computed: {
    foodData() {
      return this.listdata.filter(listdata => listdata.cat === "Food");
      },
    boekData() {
      return this.listdata.filter(listdata => listdata.cat === "Literatuur");
      },
    elecktronicaData() {
      return this.listdata.filter(listdata => listdata.cat === "Elecktronica");
      },
    },
    methods : {
      makeProgress () {
        this.foodpercentage = this.foodData.length / this.listdata.length  * 100
        this.percentage.data.push(this.foodData.length / this.listdata.length * 100);
        this.percentage.data.push(100 - this.foodpercentage);

        this.literatuurpercentage = this.boekData.length / this.listdata.length  * 100
        this.literatuur.data.push(this.boekData.length / this.listdata.length * 100);
        this.literatuur.data.push(100 - this.literatuurpercentage);

        this.elecktronicapercentage = this.elecktronicaData.length / this.listdata.length  * 100
        this.elecktronica.data.push(this.elecktronicaData.length / this.listdata.length * 100);
        this.elecktronica.data.push(100 - this.elecktronicapercentage);

        


        console.log(this.percentage)
      }
    }

}
</script>
<style lang="scss" scoped>
.avatar {
  height: 50vh;
}
.payment-box {
  background-color: #252728;
  width: 100%;
  display: flex;

     p{
      margin-bottom: 2px !important;
    }
    .price {
      display: flex;
      align-items: center;
    }
}
.wrapper-chart{
  position: relative;
  max-width: 200px;
  max-height: 250px;

  div{
    max-height: 250px;
  }
}
.wrapper-box{
  display: flex;
  justify-content: center;
}
.onderschrift p {
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    color: #BDBDBD;
}

.avatar {
  height: 50vh;
}
.payment-box {
  background-color: #252728;
  width: 100%;
  display: flex;

     p{
      margin-bottom: 2px !important;
    }
    .price {
      display: flex;
      align-items: center;
    }
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100% !important;
}
.logo-box{
  max-width: 60px;
  min-height: 60px;
  border-radius: 100px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-box {
    min-height: 40vh;
}

.payment-btn{
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
}

.categorie {
  color: #BDBDBD !important;
}
</style>
