<template>
  <v-container>
      <v-row>
          <v-col dense cols="12"  flat>
            <v-flex class="d-flex align-center flex-column justify-center mt-10 mb-4"> 
                <v-flex class="logo-box"><v-img max-width="120" max-height="120" contain :src="item.avatar" />
                </v-flex>
            </v-flex>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" flat dense>
            <v-flex>
                <p class="name text-center">Tom Jongbloets</p>
            </v-flex>
          </v-col>
      </v-row>
        <v-row class="my-4">
            <v-col dense cols="12"  flat>
                <v-flex class="width">
                    <v-text-field class="pw" flat filled solo v-model="password" type="password"></v-text-field>
                </v-flex>
                <v-flex class="payment-btn-wrapper mt-2">
                    <v-btn class="btn-payment" to="/intro" large color="primary" dark>
                    Login
                    </v-btn>
                </v-flex>
                <v-flex class="onderschrift"><p>Druk op login om te starten</p></v-flex>
            </v-col>
        </v-row>
  </v-container>
</template>
<script>

export default {
  data () {
    return {
      pageTitle: 'DataMate',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      password: 'Password',
      
      item: {
                avatar: ''
            }
        }
    },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
      this.makeProgress();
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
    },
    methods : {

      changeAvatar : function() {
        if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
          this.item.avatar = require('@/assets/avatar/head.png');
        } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
          this.item.avatar = require('@/assets/avatar/head1.png');
        }
        else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 61) {
          this.item.avatar = require('@/assets/avatar/head2.png');
        }
        else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
          this.item.avatar = require('@/assets/avatar/head3.png');
        }
        else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
          this.item.avatar = require('@/assets/avatar/head4.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
    
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.newFoodpercentage = this.foodpercentage
        this.changeAvatar();

      }

    }
   
}
</script>
<style lang="scss" >
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #222227 !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
}

.container {
    min-height: 100vh;
}

.flex.width {
    min-width: 100%;
}

.logo-box{
  max-width: 160px;
  min-height: 160px;
  width: 160px;
  border-radius: 100px;
  background: #222227;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-payment{
    font-size: 22px;
    text-transform: inherit;
    font-weight: 500;
    width: 100% !important;

    .v-btn__content{
        font-size: 16px;
    }
}

.name{
    font-size: 18px;
    color: #BDBDBD;
    font-weight: 600;
}

.onderschrift p {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    color: #BDBDBD;
}

</style>