<template>
  <v-container>
      <v-col cols="12">
            <p class="text1">Zorg je wel een beetje goed voor mij?</p>
          </v-col>
      <v-row class="avatar-box pa-4">
       <v-img :src="item.avatar"></v-img>   
      </v-row>
      <v-row>
          
      </v-row>
  </v-container>
</template>
<script>

export default {
  data () {
    return {
      pageTitle: 'DataMate',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      text: 'jeh',
      
      item: {
                avatar: ''
            }
        }
    },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
      this.makeProgress();

    },
    created(){
      setTimeout( () => this.$router.push({ path: '/overview'}), 3000);

    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
    },
    methods: {

      changeAvatar : function() {
        if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
          this.item.avatar = require('@/assets/avatar/full/avatar.png');
        } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
          this.item.avatar = require('@/assets/avatar/full/avatar1.png');
        }
        else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 61) {
          this.item.avatar = require('@/assets/avatar/full/avatar2.png');
        }
        else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
          this.item.avatar = require('@/assets/avatar/full/avatar3.png');
        }
        else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
          this.item.avatar = require('@/assets/avatar/full/avatar4.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
    
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.newFoodpercentage = this.foodpercentage
        this.changeAvatar();

      }

    }
   
}
</script>
<style lang="scss" scoped>
.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100%;
}
</style>