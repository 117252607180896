<template>
  <v-container>
      <v-row class="avatar-box pa-4">
      <v-img class="avatar-overlay" :src="item.avatarOverlay"></v-img>
      <v-img class="avatar-overlay" :src="item.avatarOverlay1"></v-img>
       <v-img :src="item.avatar"></v-img>
       
      <v-flex class="payment-btn-wrapper mt-10">
        <v-btn class="btn-payment" to="/profile-zoom" large color="primary" dark>
          Waardoor zie ik er zo uit?
        </v-btn>
      </v-flex>
       
      </v-row>
  </v-container>
</template>
<script>

export default {
  data () {
    return {
      pageTitle: 'Overview',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      newBookpercentage: Number,
      newelecktronicapercentage: Number,
      item: {
                avatar: '',
                avatarOverlay: require('@/assets/avatar/bril0.png'),
                avatarOverlay1: require('@/assets/avatar/bril0.png')
            }
        }
    },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
      this.makeProgress();
      
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
      bookData() {
      return this.listdata.filter(listdata => listdata.cat === "Literatuur");
      },
      elecktronicaData() {
        return this.listdata.filter(listdata => listdata.cat === "Elecktronica");
      },
    },
    methods : {

      changeAvatar : function() {
        if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
          this.item.avatar = require('@/assets/avatar/full/avatar.png');
        } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
          this.item.avatar = require('@/assets/avatar/full/avatar1.png');
        }
        else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 61) {
          this.item.avatar = require('@/assets/avatar/full/avatar2.png');
        }
        else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
          this.item.avatar = require('@/assets/avatar/full/avatar3.png');
        }
        else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
          this.item.avatar = require('@/assets/avatar/full/avatar4.png');
        }
    },

    changeOverlay : function() {
        if (this.newBookpercentage > 0 && this.newBookpercentage < 20) {
          this.item.avatarOverlay = require('@/assets/avatar/avatar-bril.png');
        } else if (this.newBookpercentage > 19 && this.newBookpercentage < 100) {
          this.item.avatarOverlay = require('@/assets/avatar/avatar-bril.png');
        }
    },
        changeOverlay1 : function() {
        if (this.newelecktronicapercentage > 0 && this.newelecktronicapercentage < 30) {
          this.item.avatarOverlay1 = require('@/assets/avatar/bril0.png');
        } else if (this.newelecktronicapercentage > 29 && this.newelecktronicapercentage < 100) {
          this.item.avatarOverlay1 = require('@/assets/avatar/avatar-vr.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.bookItems = this.bookData.length
        this.bookpercentage = this.bookItems / this.totalItems * 100
        this.newBookpercentage = this.bookpercentage;

        this.elecktronicaItems = this.elecktronicaData.length
        this.elecktronicapercentage = this.elecktronicaItems / this.totalItems * 100
        this.newelecktronicapercentage = this.elecktronicapercentage;

        this.newFoodpercentage = this.foodpercentage
        this.changeAvatar();
        this.changeOverlay();
        this.changeOverlay1();

      }

    }
   
}
</script>
<style lang="scss">
.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100%;
}
.avatar-overlay{
    position: absolute;
    z-index: 1;
    width: 92%;
}

</style>