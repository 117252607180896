<template>
  <v-container>
    <v-row class="pa-10">
      <v-flex class="avatar-box">
         <img :src="item.avatar">
         <img class="overlay" :src="item.avatarOverlay">
      </v-flex>
    </v-row>
    <v-row class="my-4 pay-wrapper-gegevens" >
      <v-col dense  cols="4"  flat>
        <v-flex class="logo-box"><v-img max-width="60" max-height="60" contain :src="item.avatar" /></v-flex>
      </v-col>
      <v-col dense  cols="4"  flat>
        <v-icon dark class="icon-pay">
              mdi-arrow-right
        </v-icon>
      </v-col>
      <v-col dense  cols="4"  flat>
          <v-flex class="logo-box  align-self-end"><v-img max-width="60" max-height="60" contain :src="item.logo" /></v-flex>
      </v-col>
    </v-row>
    <v-select @change="changeItem()"  v-model="item.name" :items="companies" item-text="name" label="Wie ga je betalen?" filled solo></v-select>
    <!-- <v-text-field v-model="item.price" class="money-bar" label="Amount" value="10.00" prefix="€"></v-text-field> -->
    <currency-input  class="currency" v-model="item.price" currency="EUR" locale="nl" />
    <v-flex class="onderschrift"><p>Het te betalen bedrag</p></v-flex>
    <v-flex class="payment-btn-wrapper mt-10">
      <v-btn class="btn-payment" @click="addItem()" large color="primary" dark>
        Doe betaling
      </v-btn>
    </v-flex>
  </v-container>
</template>

<script>
export default {
    props: [
        'listdata'
    ],
    data: () => {
    return {
      pageTitle: 'Make payment',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      companies: [{
        name: 'Albert Hein',
        cat: 'Food', 
      },
      {
        name: 'Media Markt',
        cat: 'Elecktronica', 
      },
      {
        name: 'Bruna',
        cat: 'Literatuur',
      }],
      item: {
                name: '', 
                cat: 'Literatuur',
                price: 123.45, 
                time: '18:25',
                logo: require('@/assets/logos/default.png'),
                avatar: require('@/assets/avatar/head2.png'),
                avatarOverlay: require('@/assets/avatar/bril0.png')
            },
        }
    },
    mounted () {
        this.$emit('createdTitle', this.pageTitle);
        this.makeProgress();
        this.changeAvatar();
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
    },
    methods: {
        addItem : function(){
          if (this.item.name == 'Albert Hein') {
              console.log('jeh')
              this.item.cat = 'Food';
            }else if (this.item.name == 'Media Markt') {
              console.log('neh')
              this.item.cat = 'Elecktronica';
            }
            else {
              console.log('mediamarkt')
              this.item.cat = 'Literatuur';
            }
            this.$emit('clickedItem', this.item);
            this.$router.push('/profile')  
    },
    changeItem : function(){
      if (this.item.name == 'Albert Hein') {
                this.newFoodpercentage = this.foodpercentage
                this.newFoodItems = this.foodItems
                this.newTotalItems = this.totalItems

                console.log(this.newFoodpercentage,this.newFoodItems, this.newTotalItems)

                this.newFoodItems++;
                this.newTotalItems++;

                console.log(this.newFoodItems,this.newTotalItems)

                this.newFoodpercentage = this.newFoodItems / this.newTotalItems * 100
                this.item.logo = require('@/assets/logos/ah.png');
                this.item.avatarOverlay = require('@/assets/avatar/bril0.png');

                this.changeAvatar();

                // this.makeProgress ()
            }else if (this.item.name == 'Blokker') {
                this.newFoodpercentage = this.foodpercentage
                this.newFoodItems = this.foodItems
                this.newTotalItems = this.totalItems

                console.log(this.newFoodpercentage,this.newFoodItems, this.newTotalItems)

                this.newTotalItems++;

                console.log(this.newFoodItems,this.newTotalItems)

                this.newFoodpercentage = this.newFoodItems / this.newTotalItems * 100
                this.item.logo = require('@/assets/logos/blokker.jpeg');
                this.item.avatarOverlay = require('@/assets/avatar/bril0.png');

                this.changeAvatar();

            } else if (this.item.name == 'Bruna'){
                this.newFoodpercentage = this.foodpercentage
                this.newFoodItems = this.foodItems
                this.newTotalItems = this.totalItems

                console.log(this.newFoodpercentage,this.newFoodItems, this.newTotalItems)

                this.newTotalItems++;

                console.log(this.newFoodItems,this.newTotalItems)

                this.newFoodpercentage = this.newFoodItems / this.newTotalItems * 100
                this.item.logo = require('@/assets/logos/bruna.png');
                this.item.avatarOverlay = require('@/assets/avatar/bril.png');

                this.changeAvatar();

            } else if (this.item.name == 'Media Markt') {
                this.newFoodpercentage = this.foodpercentage
                this.newFoodItems = this.foodItems
                this.newTotalItems = this.totalItems

                console.log(this.newFoodpercentage,this.newFoodItems, this.newTotalItems)

                this.newTotalItems++;

                console.log(this.newFoodItems,this.newTotalItems)

                this.newFoodpercentage = this.newFoodItems / this.newTotalItems * 100
                this.item.logo = require('@/assets/logos/mediamarkt.png');

                this.item.avatarOverlay = require('@/assets/avatar/vr.png');

                this.changeAvatar();

            }
            
      
    },
    changeAvatar : function() {
      if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
        this.item.avatar = require('@/assets/avatar/head.png');
      } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
        this.item.avatar = require('@/assets/avatar/head1.png');
      }
      else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 60) {
        this.item.avatar = require('@/assets/avatar/head2.png');
      }
      else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
        this.item.avatar = require('@/assets/avatar/head3.png');
      }
      else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
        this.item.avatar = require('@/assets/avatar/head4.png');
        console.log('jeh')
      }
    },
    changeOverlay : function() {
        if (this.newBookpercentage > 0 && this.newBookpercentage < 30) {
          this.item.avatarOverlay = require('@/assets/avatar/bril0.png');
        } else if (this.newBookpercentage > 30 && this.newBookpercentage < 100) {
          this.item.avatarOverlay = require('@/assets/avatar/bril.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.newFoodpercentage = this.foodpercentage;

        this.bookItems = this.bookData.length
        this.bookpercentage = this.bookItems / this.totalItems * 100

        this.newBookpercentage = this.bookpercentage;

        this.changeAvatar();
        this.changeOverlay();

      }
  },
  
}
</script>
<style lang="scss" scoped>
.money-bar{
    input#input-6 {
      text-align: center !important;
  }
}
.avatar-box {
  height: 36vh;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
}
.v-select > .v-input__control > .v-input__slot {
  background: #222227;
}
.currency {
    width: 100%;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    color: #BDBDBD;
}

.onderschrift p {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    color: #BDBDBD;
}
.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100% !important;
}
.logo-box{
  max-width: 80px;
  min-height: 80px;
  border-radius: 100px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-pay{
  font-size: 48px;
  color: #BDBDBD;
}

.pay-wrapper-gegevens{
  .col-4{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.overlay {
    position: absolute;
    height: 36vh !important;
}
</style>