<template>
  <v-container>
      <v-row>
            <v-col cols="12">
                <v-img max-height="120" contain src="../assets/profile.png"></v-img>
            </v-col>
            <v-col cols="12" class="chat-wrapper">
                <div class="arrow-up"></div>
                <div class="chat">
                    <p>Gelukkig vergeet je hometown niet. <strong>96%</strong> van al je betalingen komen dus uit <strong>Tilburg</strong></p>
                </div>
            </v-col>
        </v-row>
        <v-row class="my-12">
            <v-col cols="12">
                <v-img class="map" max-width="300" center contain src="../assets/maptilburg.png"></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-flex class="payment-btn-wrapper mx-4 my-10">
                <v-btn class="btn-payment" href="/bedrijven" x-large color="primary" dark>
                    Bij wie deed je deze betalingen?
                </v-btn>
            </v-flex>
        </v-row>
  </v-container>
</template>
<script>

export default {
  data () {
    return {
      pageTitle: 'HomeTown',
      foodItems: Number,
      totalItems: Number,
      newTotalItems: Number,
      newFoodItems: Number,
      foodpercentage: Number,
      newFoodpercentage: Number,
      text: 'jeh',
      
      item: {
                avatar: ''
            }
        }
    },
    props: [
        'listdata'
    ],
    mounted () {
      this.$emit('createdTitle', this.pageTitle);
      this.makeProgress();

    },
    created(){
    },
    computed: {
      foodData() {
        return this.listdata.filter(listdata => listdata.cat === "Food");
        },
    },
    methods: {

      changeAvatar : function() {
        if (this.newFoodpercentage > 0 && this.newFoodpercentage < 20) {
          this.item.avatar = require('@/assets/avatar/head.png');
        } else if (this.newFoodpercentage > 19 && this.newFoodpercentage < 40) {
          this.item.avatar = require('@/assets/avatar/head1.png');
        }
        else if (this.newFoodpercentage > 39 && this.newFoodpercentage < 61) {
          this.item.avatar = require('@/assets/avatar/head2.png');
        }
        else if (this.newFoodpercentage > 59 && this.newFoodpercentage < 80) {
          this.item.avatar = require('@/assets/avatar/head3.png');
        }
        else if (this.newFoodpercentage > 79 && this.newFoodpercentage < 101) {
          this.item.avatar = require('@/assets/avatar/full/avatar4.png');
        }
    },
    makeProgress () {
        console.log('jeh')
        this.totalItems = this.listdata.length
        this.foodItems = this.foodData.length
    
        this.foodpercentage = this.foodItems / this.totalItems * 100

        this.newFoodpercentage = this.foodpercentage
        this.changeAvatar();

      }

    }
   
}
</script>
<style lang="scss">

.chat {
    width: 80%;
    margin: 0 auto;
    background: #252728;
    padding: 20px 10px;
    border-radius: 10px;
    margin-top: -28px;
    font-size: 14px;
    text-align: center;
    z-index: 9;

    strong{
        color: #cbe9ba;
        font-weight: 800;
    }
    
    p{
        color: #ACADAC;
        margin-bottom: 0;
    }
}

h1{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #EAE6E4;
}

.chat-wrapper{
    margin-top: -40px;
    z-index: 9;
}

.mate{
    p{
        text-align: center;
        font-weight: 800;
        font-size: 13px;
        margin: 10px 0;
    }
}

.map{
    margin: 0 auto;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #252728;
    top: 0px;
    text-align: center;
    margin: 0 auto;
    z-index: 9;
    margin-bottom: 28px;
}

.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100%;
}
</style>
<style lang="scss">

.chat {
    width: 80%;
    margin: 0 auto;
    background: #252728;
    padding: 20px 10px;
    border-radius: 10px;
    margin-top: -28px;
    font-size: 14px;
    text-align: center;
    z-index: 9;

    strong{
        color: #CBE9BA;
        font-weight: 800;
    }
    
    p{
        color: #ACADAC;
        margin-bottom: 0;
    }
}

h1{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #EAE6E4;
}

.chat-wrapper{
    margin-top: -40px;
    z-index: 9;
}

.mate{
    p{
        text-align: center;
        font-weight: 800;
        font-size: 13px;
        margin: 10px 0;
    }
}

.map{
    margin: 0 auto;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #252728;
    top: 0px;
    text-align: center;
    margin: 0 auto;
    z-index: 9;
    margin-bottom: 28px;
}

.payment-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
    margin: 0 auto;
}
.btn-payment{
    font-size: 18px;
    text-transform: inherit;
    font-weight: 500;
    width: 100%;
}
</style>